import { css } from '@emotion/react';
import styled from '@emotion/styled';
import StyledIcon from '../../components/global/Icon.style';
import { pxToRem } from '../../theme/Theme';

export const globalStyle = (theme) => css`
  body {
    --top-bar-offset: 0px;
    --top-bar-height: 60px;

    ${theme.media.desktop} {
      --top-bar-height: 40px;
    }
  }
  body.top-bar-hidden {
    --top-bar-offset: calc(0px - var(--top-bar-height));
  }
  ${TopBarSection} {
    transform: translateY(var(--top-bar-offset));
    transition: transform 350ms ease-in-out;
  }
  [data-wl-module='TopBar'] {
    height: var(--top-bar-height);
    background-color: ${theme.colors.pacificBlue};
  }
`;

const TopBarSection = styled.div`
  --layout-extra-pl: 0px;
  --layout-extra-pr: 0px;

  position: absolute;
  height: var(--top-bar-height);

  ${({ theme }) => theme.layout.container};

  width: 100%;
  max-width: 100vw;
  top: 0;
  z-index: 994;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  column-gap: 20px;
  background-color: ${({ theme }) => theme.colors.whiteSmoke};
  padding-top: 12px;
  padding-bottom: 12px;
  color: ${({ theme }) => theme.colors.trout};
  ${({ theme }) => theme.fonts.september.medium};
  font-size: ${pxToRem(14)};

  ${({ theme }) => theme.media.laptop} {
    font-size: ${pxToRem(16)};
  }
  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 1281px) {
    padding: 0;
  }

  .link-label {
    strong {
      font-weight: ${({ theme }) => theme.weights.bold};
    }
  }

  & > a > ${StyledIcon} {
    color: ${({ theme }) => theme.colors.pacificBlue};
  }

  .mobile-top {
    column-gap: 20px;
  }

  .mobile-bottom {
    column-gap: 8px;
    max-height: 25px;
    border-top: 1px solid ${({ theme }) => theme.colors.neutralGray}; 

    ${({ theme }) => theme.media.desktop} {
      border: none;
    }
  }

  .mobile-top,
  .mobile-bottom {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-shrink: 0;

    ${({ theme }) => theme.media.desktopSmall} {
      column-gap: 20px;
    }

    ${({ theme }) => theme.media.desktop} {
      width: fit-content;
    }
  }

  .usp {
    display: flex;
    align-items: center;
    line-height: normal;
    svg {
      margin-right: 2px;
    }

    ${({ theme }) => theme.media.desktopSmall} {
      font-size: ${pxToRem(14)};

      svg {
        margin-right: 8px;
      }
    }
  }

  .feedback {
    display: flex;
    align-items: center;
    gap: 6px;

    ${({ theme }) => theme.media.desktopSmall} {
      gap: 12.5px;
    }
    .stars {
      height: 18px;
    }
    .score {
      font-weight: ${({ theme }) => theme.weights.bold};
    }
    .link {
      display: none;

      ${({ theme }) => theme.media.desktopSmall} {
        display: inline;
      }

      color: ${({ theme }) => theme.colors.pacificBlue};
      transition: color 150ms linear;
      ${({ theme }) => theme.media.hover} {
        &:hover {
          text-decoration: underline;
        }
      }
      &:active {
        color: ${({ theme }) => theme.colors.bahamaBlue};
      }
    }
    .chat-logo {
      display: none;

      ${({ theme }) => theme.media.desktopSmall} {
        display: inline;
      }
    }
  }
`;

export default TopBarSection;
