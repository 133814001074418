import styled from '@emotion/styled';
import { lighten } from 'polished';

export default styled.a`
  display: flex;
  align-items: center;
  margin: 5px 0;
  width: fit-content;

  ${({ variant, theme }) => `
    ${theme.typography[variant]?.styles};
  `}

  .link-label {
    ${({ underline, theme }) => underline && `
      ${theme.typography.underline.styles};
    `}
  }

  svg {
    margin-right: 12px;
    width: 18px;

    ${({ iconColor, color, theme }) => `
      fill: ${theme?.colors[iconColor || color]};
    `}
  }

  transition: color 150ms linear;
  ${({ color = 'white', theme }) => `
    color: ${theme?.colors[color]};
  `}

  ${({ theme }) => theme.media.hover} {
    &:hover {
      color: ${({ theme }) => lighten(0.2, theme.colors.pacificBlue)};
      svg {
        fill: ${({ theme }) => lighten(0.2, theme.colors.pacificBlue)};
      }
    }
  }
`;
