import React, { useEffect, useState } from 'react';
import { registerModule } from '../../app/modules/reactModules';
import * as Self from './module';
import { Global } from '@emotion/react';
import TopBarSection, { globalStyle } from './module.style';
import useAutoHide from '../../app/hooks/useAutoHide';
import { Phone, Whatsapp, CheckmarkFull } from '../../app/helpers/icons';
import { useBreakpoint } from '../../app/providers/breakpointProvider';
import getFeedbackSummary from '../../app/resources/feedbackSummary';
import isEmbedded from '../../app/helpers/isEmbedded';
import Icon from '../../components/global/Icon';
import Fixed from '../../components/global/Fixed';
import Typography from '../../components/global/Typography';
import ContactWithIcon from '../../components/ContactWithIcon';
import { Stars } from '../../components/Stars';

const numberFormat = Intl.NumberFormat('nl-NL', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
});

export const getServerSideProps = async () => {
  const [feedback] = await Promise.all([getFeedbackSummary()]);
  const finalScore = feedback.statistics.find(
    (a) => a.question_type === 'final_score'
  );
  const score = Number(finalScore.value);
  const maxScore = Number(finalScore.maxscore);
  return {
    props: {
      feedbackSummary: {
        score: (score / maxScore) * 10,
        maxScore: 10,
        count: Number(finalScore.count),
      },
    },
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const TopBarGlobalModule = ({
  phoneContact,
  whatsAppContact,
  usps,
  uspsMobile,
  feedbackSummary,
  feedback,
  flatpack,
}) => {
  useAutoHide({
    enabled: true,
    bodyClass: 'top-bar-hidden',
    shouldShow: (oldY, newY) => newY <= 60 || oldY > newY,
  });

  const showTopBar = !isEmbedded();
  const breakpoint = useBreakpoint();

  const phoneContactText = breakpoint.equalsOrUp('laptop') ? (
    <>
      Bel <strong>{phoneContact.linkName}</strong> voor de slimste leasedeal
    </>
  ) : (
    <strong>{phoneContact.linkName}</strong>
  );
  const whatsAppContactText = breakpoint.equalsOrUp('laptop') ? (
    <>
      Stel je vraag via <strong>{whatsAppContact.linkName}</strong>
    </>
  ) : (
    <strong>{whatsAppContact.linkName}</strong>
  );

  const uspsData = breakpoint.equalsOrUp('laptop') ? usps : uspsMobile;

  const showFeedback = !!feedbackSummary;
  const score = feedbackSummary?.score;
  const maxScore = feedbackSummary?.maxScore;
  const feedbackText = feedback.link?.content?.replace(
    '<!--COUNT-->',
    feedbackSummary?.count
  );
  const feedbackLink = feedback.link?.href;

  return (
    <>
      {showTopBar && (
        <>
          <Global styles={globalStyle} />
          <Fixed className="fixed-content" zIndex={970} flatpack={flatpack}>
            <TopBarSection>
              <div className='contact mobile-top'>
                {/* CONTACT LINKS */}
                <ContactWithIcon
                  iconPath={Phone.d}
                  iconSize={Phone.size}
                  href={phoneContact.linkRef}
                  target={phoneContact.target}
                  color="trout"
                  iconColor="pacificBlue"
                  variant="septemberRegular"
                  className="contact-item"
                >
                  {phoneContactText}
                </ContactWithIcon>
                <ContactWithIcon
                  iconPath={Whatsapp.d}
                  iconSize={Whatsapp.size}
                  href={whatsAppContact.linkRef}
                  target={whatsAppContact.target}
                  color="trout"
                  iconColor="pacificBlue"
                  variant="septemberRegular"
                  className="contact-item"
                >
                  {whatsAppContactText}
                </ContactWithIcon>
              </div>

              <div className="mobile-bottom">
                {/* USPS */}
                {uspsData?.map((usp, i) => (
                  <Typography key={i} className="usp" variant="controlSmall" color="trout">
                    <Icon fill="emerald" {...CheckmarkFull} />
                    {usp.content}
                  </Typography>
                ))}

                {/* FEEDBACK */}
                <div className="feedback">
                  {showFeedback && (
                    <>
                      <span className="score">{numberFormat.format(score)}</span>
                      <Stars
                        stars={(score / maxScore) * 5}
                        maxStars={5}
                        height={18}
                      />
                      <a className="link" href={feedbackLink}>
                        {feedbackText}
                      </a>
                      <Icon
                        width="25"
                        height="24"
                        className="chat-logo"
                        d={[
                          'M14.493 1.62119C14.0806 1.62119 13.7451 1.95644 13.7451 2.36911V10.637C13.7451 11.0497 14.0806 11.3853 14.493 11.3853H20.4944C20.6411 11.3853 20.7851 11.4287 20.9076 11.5098L23.5045 13.2332V2.36911C23.5045 1.95644 23.1694 1.62119 22.757 1.62119H14.493ZM24.2524 15.375C24.1081 15.375 23.9638 15.3333 23.839 15.2505L20.269 12.8815H14.493C13.2563 12.8815 12.25 11.8747 12.25 10.637V2.36911C12.25 1.13177 13.2563 0.125 14.493 0.125H22.757C23.9937 0.125 25 1.13177 25 2.36911V14.6271C25 14.9024 24.8486 15.1559 24.6064 15.2865C24.4953 15.3454 24.3739 15.375 24.2524 15.375Z',
                          'M6.73354 23.875C6.61469 23.875 6.49519 23.8456 6.38658 23.7857C6.14856 23.6547 6 23.4003 6 23.1229V12.3806C6 11.1373 6.9874 10.125 8.20094 10.125H9.42373C9.82912 10.125 10.1576 10.4616 10.1576 10.8771C10.1576 11.2922 9.82912 11.6289 9.42373 11.6289H8.20094C7.79654 11.6289 7.46741 11.9662 7.46741 12.3806V21.7222L9.59473 20.2762C9.7149 20.1943 9.85586 20.1507 10.0005 20.1507H15.0487C15.4535 20.1507 15.7826 19.8137 15.7826 19.3993V15.7663C15.7826 15.3512 16.1111 15.0145 16.5165 15.0145C16.9215 15.0145 17.25 15.3512 17.25 15.7663V19.3993C17.25 20.6426 16.2626 21.6549 15.0487 21.6549H10.2213L7.13926 23.7498C7.01678 23.833 6.87549 23.875 6.73354 23.875Z',
                          'M4.0133 14.8182C4.16909 15.1552 4.05685 15.5568 3.74997 15.7625L1.15618 17.4998C1.03189 17.583 0.888494 17.625 0.744432 17.625C0.623822 17.625 0.502542 17.5956 0.391983 17.5357C0.150763 17.4047 0 17.1503 0 16.8729V6.13063C0 4.88525 1.00006 3.875 2.23363 3.875H9.4632C9.8525 3.875 10.2073 4.15784 10.2462 4.54929C10.2907 4.9996 9.94195 5.37921 9.50574 5.37921H2.23363C1.82255 5.37921 1.4892 5.71551 1.4892 6.13063V15.4722L2.9268 14.509C3.30639 14.2549 3.82099 14.4014 4.0133 14.8182Z',
                        ].join('')}
                      />
                    </>
                  )}
                </div>
              </div>
            </TopBarSection>
          </Fixed>
        </>
      )}
    </>
  );
};

export default TopBarGlobalModule;

registerModule({ TopBar: Self });
