import React from 'react';
import StyledContactWithIcon from './ContactWithIcon.style';
import Icon from './global/Icon';

export default function ContactWithIcon({
  iconPath,
  iconSize,
  children,
  color,
  iconColor,
  variant = "intro",
  underline,
  ...p
}) {
  return (
    <StyledContactWithIcon
      color={color}
      iconColor={iconColor}
      variant={variant}
      underline={underline}
      {...p}
    >
      <Icon size={iconSize} d={iconPath} />
      <div className="link-label">{children}</div>
    </StyledContactWithIcon>
  );
}
